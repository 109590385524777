.app-header {
    background-color: white;
    padding: 10px;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: sticky;
    z-index: 10000;
    top: 0;

}

.app-header nav ul {
    list-style-type: none;
    padding: 0;
}

.app-header nav ul li {
    display: inline;
    margin: 0 10px;
    font-size: 20px;
}

.app-header nav ul li a {
    color: black;
    text-decoration: none;
}

.app-header nav ul li a:hover {
    text-decoration: underline;
}

.app-header-logo {
    /* position: absolute; */
    padding: 5px;
    height: 12vh;

}

.app-header h1 {
    /* position: relative;
    top: 10px;
    left: 10px; */
    /* left: 50vh; */
    /* padding: 10px; */
    color: black;
    /* padding: 0; */
    /* z-index: 100; */
}

.app-header h2 {
    /* position: relative;
    top: 10px;
    left: 10px; */
    /* padding: 10px; */
    /* padding: 0; */
    /* margin: 0 0 -10px 0; */
    margin-top: -20px;
    color: red;
}

.app-header-contact-text {
    /* position: absolute; */
    padding: 10px;
    height: 12vh;
    box-shadow: -2px 0 0 black;
}

.app-header-nav {
    /* position: absolute; */
    /* padding: 10px;
    height: 12vh; */
    /* box-shadow: -2px 0 0 black; */
    /* position: absolute; */
    margin-left: auto;
    margin-right: 40px;
    text-align: right;
    color: black;
}

.nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}


/* Media query for screens smaller than 1000px (e.g., tablets and mobile devices) */
@media (max-width: 1000px) {
	.nav-btn {
		visibility: visible;
		opacity: 1;
        position: absolute;
        top: 5px;
        right: 5px;
	}

	.responsive_nav {
		transform: translateY(100vh);
	}

    .hide_nav {
        transform: translateY(-100vh);
    }

	.nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

    .app-header {
        flex-direction: column;
    }

    .app-header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: white;
		transition: 1s;

	}

    .app-header nav ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 30px;
	}

    .app-header nav ul li {
        margin: 10px;
    }

    .app-header-contact-text {
        padding: 10px;
        height: 8vh;
        box-shadow: 0 -2px 0 black;
        font-size: smaller;
    }


  }