.contact {
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

form {
    max-width: 600px;
    width: 100%;
    background: white;
    padding: 25px 25px 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 25px;
}

form h2 {
    text-align: center;
    color: black;
    font-weight: 500;
}

form .input-box {
    margin-top: 20px;
}

.input-box .field {
    width: 90%;
    height: 50px;
    background: transparent;
    border: 2px solid #ddd;
    outline: none; 
    border-radius: 6px;
    padding: 15px;
    font-size: 16px;
    margin-top: 8px;
}

.input-box .field.mess {
    height: 200px;
    resize: none;
}

form button {
    width: 100%;
    height: 55px;
    background: #ddd;
    border: none;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0,1);
    cursor: pointer;
    font-size: 16px;
    color: black;
    font-weight: 500;
    margin-top: 25px;
    transition: 0.5s;
}

form button:hover {
    background-color: #eeeeee;
}