/* Testimonial container */
.testimonial-container {
    max-width: 800px;
    /* margin-inline: auto; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* justify-content: center; */
    /* margin-top: -5%; */
  }
  
  /* Slider container */
  .slider-container {
    position: relative;
    user-select: none;
    padding-inline: 1rem;
    /* height: 20%; */
    /* width: 90%; */
  }
  
  .slider-container .quote {
    position: absolute;
    z-index: -1;
  }
  
  .slider-container .top-quote {
    position: absolute;
    top: -1rem;
    left: -1rem;
  }
  
  .slider-container .bottom-quote {
    position: absolute;
    bottom: -3rem;
    right: -0.5rem;
    transform: rotate(180deg);
  }
  
  .slider-container .review-img {
    /* width: 50%;
    height: 50%; */
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
    margin-inline: auto;
  }
  
  /* Splide customization */
  .splide__track {
    border-radius: 1rem;
  }
  
  .splide__slide {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 3rem 1.875rem;
    border-radius: 1rem;
    gap: 1rem;
  }
  
  .slider-container .content .text {
    color: #484646;
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }
  
  .slider-container .content .rating {
    display: inline-block;
    line-height: 1;
  }
  
  .slider-container .content .star {
    color: #ff6f59;
    font-size: 1.1rem;
  }
  
  .slider-container .content .user {
    font-weight: 600;
  }
  
  .splide__pagination__page.is-active {
    background-color: #ff6f59;
  }
  
  .splide__arrow {
    position: absolute;
    background-color: transparent;
    border: none;
    font-size: 2rem;
    color: #505050;
    font-weight: 300;
  }
  
  .splide__arrow--prev {
    top: 50%;
    left: -3.5rem;
  }
  
  .splide__arrow--next {
    top: 50%;
    right: -3.5rem;
  }
  
  .splide__pagination__page {
    height: 10px;
    width: 10px;
    opacity: 1;
  }
  
  /* Media query for screens smaller than 1000px (e.g., tablets and mobile devices) */
@media (max-width: 1000px) {

  .slider-container {
    /* display: None; */
    width: 20vh;
  }

  .slider-container .content .text {
    font-size: 0.9rem;
  }

  .slider-container .content .star {
    font-size: 0.9rem;
  }


}