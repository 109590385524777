@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet");

.App {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-background-image-1 {
  background-image: url('./images/house_lighting.jpeg');
  background-size: cover;  /* Ensures the image covers the entire window */
  background-repeat: no-repeat;  /* Prevents the image from repeating */
  background-position: center;  /* Centers the image */
  background-attachment: fixed;
  height: 80vh;  /* Makes sure the body takes up the entire viewport height */
  margin: 0;  /* Removes default margin */
  overflow: hidden;  /* Hides any overflow */
  display: flex;
  flex-direction: column-reverse; /* Reverse the column direction */
  justify-content: flex-start; /* Align items to the start (bottom) */
  align-items: flex-start; /* Align items to the left */
  padding: 20px;
}

.App-background-image-2 {
  background-image: url('./images/city_lights.jpeg');
  background-size: cover;  /* Ensures the image covers the entire window */
  background-repeat: no-repeat;  /* Prevents the image from repeating */
  background-position: center;  /* Centers the image */
  background-attachment: fixed;
  height: 80vh;  /* Makes sure the body takes up the entire viewport height */
  margin: 0;  /* Removes default margin */
  overflow: hidden;  /* Hides any overflow */
}

.App-background-image-3 {
  background-image: url('./images/map.jpeg');
  background-size: cover;  /* Ensures the image covers the entire window */
  background-repeat: no-repeat;  /* Prevents the image from repeating */
  background-position: center;  /* Centers the image */
  /* background-attachment: fixed; */
  height: 80vh;  /* Makes sure the body takes up the entire viewport height */
  /* margin-top: 50px;  Removes default margin */
  overflow: hidden;  /* Hides any overflow */
}

.App-main-text {
  background-color: white;
  justify-content: center;
  color: red;
}

.App-content {
  background-color: #e7e7e7;
  border: 2px solid ##e7e7e7; /* Border with a color and width */
  border-radius: 15px; /* Adjust the radius as needed */
  /* height: 50vh; */
  margin: 10%;
  padding: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

p {
  font-size: 20px;
}

.App-map-image {
  background-color: #e7e7e7;
  border: 2px solid ##e7e7e7; /* Border with a color and width */
  border-radius: 15px; /* Adjust the radius as needed */
  /* height: 50vh; */
  margin: 0 10% 0 10%;
  padding: 10px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: inline-block; 
  text-align: center;

}

.App-contact-form {
  background-color: #e7e7e7;
  border: 2px solid ##e7e7e7; /* Border with a color and width */
  border-radius: 15px; /* Adjust the radius as needed */
  /* height: 60vh; */
  margin: 10%;
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

.image-h1 {
  color: white;
  font-weight: 500;
  font-size: 50px;
}

.image-h2 {
  color: white;
  font-weight: 100;
  font-size: 50px;
  margin-top: -20px;
}

.content-h1 {
  color: black;
  font-weight: 500;
  font-size: 50px;
  text-align: center; /* Center the text inside the div */
}

.content-h1-map {
  color: black;
  font-weight: 500;
  font-size: 50px;
  text-align: center; /* Center the text inside the div */
}

.content-h2 {
  color: black;
  font-weight: 700;
  font-size: 25px;
  margin-top: -20px;
  text-align: center; /* Center the text inside the div */
}

.image2-h1 {
  color: white;
  font-weight: 500;
  font-size: 50px;
  text-align: center; /* Center the text inside the div */
}

.image3-h1 {
  color: black;
  font-weight: 600;
  font-size: 40px;
  text-align: center; /* Center the text inside the div */
}

.bullet-points {
  list-style-type: disc; /* Default bullet points */
  margin: 20px; /* Add margin around the list */
  padding-left: 20px; /* Add padding to the left of the list */
  display: flex;
  flex-direction: row; /* Arrange columns horizontally */
}

.bullet-points li {
  margin-bottom: 10px; /* Add margin below each list item */
  padding: 5px; /* Optional: Add padding for spacing */
  color: white;
  font-size: 20px;
}

.services-container {
  align-items: flex-start; /* Align items to the top */
  /* padding: 20px; Add padding around the container */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
  flex-wrap: wrap; /* Allow columns to wrap to the next line */
  margin-top: -20px;
}

.column {
  flex: 1; /* Each column takes up equal space */
  margin: 10px; /* Add margin around columns */
  padding: 20px; /* Optional: Add padding for spacing */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.map-image {
  /* position: absolute; */
  padding: 10px;
  /* height: 40vh; */
  width: 90%;
  border: 5px solid ##e7e7e7; /* Border with a color and width */
  border-radius: 30px; /* Adjust the radius as needed */
}

/* Media query for screens smaller than 900px (e.g., tablets and mobile devices) */
@media (max-width: 1000px) {
  .bullet-points {
    flex-direction: column; /* Arrange columns horizontally */
  }

  .App-map-image {
    height: 50vh;
  }
  .column h1 {
    text-align: center; /* Center the text inside the column */
  }

  .image-h1 {
    font-size: 35px;
  }

  .image-h2 {
    font-size: 35px;
  }

  .content-h1 {
    font-size: 35px;
  }

  .content-h1-map {
    display: None
  }

  /* .App-content {
    height: 100vh;
  } */

  .bullet-points li {
    font-size: 18px;
    padding: 2px;
  }

  .App-map-image {
    display: None;
  }
}


/* Media query for screens bigger than 1500px (e.g., tablets and mobile devices) */
@media (min-width: 1500px) {
  .map-image {
    width: 60%;
  }


}
